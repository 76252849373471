<template>
  <div class="hero-wrapper" :class="heroWrappeClass">
    <div class="hero"></div>
    <div class="container">
      <div class="stripes"></div>
      <div class="description description-alt">
        <div class="desc mb-15">
          <div class="focus-title my-10">
            <h1 class="main-text mb-5">
              {{ dynamicMainText }}
            </h1>
            <p class="sub-text">
              {{ dynamicSubText }}
            </p>
          </div>
        </div>

        <div class="text-center">
          <a :href="dynamicBtnLink" class="btn btn-light">
            {{ dynamicBtnText }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    filterValue: {
      type: String,
      required: true
    },
    filterKind: {
      type: String,
      required: true
    },
    mainText: {
      type: String,
      optional: true
    },
    subText: {
      type: String,
      optional: true
    },
    btnText: {
      type: String,
      optional: true
    }
  },
  computed: {
    heroWrappeClass() {
      return `hero-banner-type-${this.filterValue}`;
    },
    dynamicMainText() {
      if (this.mainText) {
        return this.mainText;
      }

      return `RENT DESIGNER ${this.filterValue.toUpperCase()}`
    },
    dynamicSubText() {
      if (this.subText) {
        return this.subText;
      }

      return `Borrow bold and dazzling ${this.filterValue} to make a statement at your next special occasion.`
    },
    dynamicBtnText() {
      if (this.btnText) {
        return this.btnText;
      }

      return `BROWSE ALL ${this.filterValue.toUpperCase() }`
    },
    dynamicBtnLink() {
      return `/rent-jewelry?${this.filterKind}=${this.filterValue}`;
    }
  }
}
</script>