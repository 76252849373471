import Vue from 'vue/dist/vue.esm';
import Helpers from '../helpers';
import Slick from 'slick-carousel';

import ReferFriendModal from '../components/refer_friend/modal';
import ReferFriendTriggerBtn from '../components/refer_friend/btn';
import ReferFriendTriggerMobileBtn from '../components/refer_friend/btn';
import HowItWorksBanner from "../components/how_it_works/how_it_works_banner";
import HowItWorksProducts from '../components/how_it_works/products';

export default class Visitors {
  index() {
    $('.home-slider').slick({
      dots: true,
      nextArrow: false,
      prevArrow: false,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 3000,
    });

    $(window).scroll(function () {
      $('#featured-1').each(function () {
        var imagePos = $(this).offset().top;

        var topOfWindow = $(window).scrollTop();
        if (imagePos < topOfWindow + 400) {
          $(this)
            .find('h4, a')
            .addClass('fadeIn');
          $(this)
            .find('h2')
            .addClass('slideRight');
        }
      });
    });

    new Vue({
      el: '#refer-friend-app',
      components: {
        ReferFriendModal: ReferFriendModal,
        ReferFriendTriggerBtn: ReferFriendTriggerBtn,
        ReferFriendTriggerMobileBtn: ReferFriendTriggerMobileBtn,
      },
      data: {
        modalIsVisible: false,
      },
      methods: {
        toggleModal() {
          this.modalIsVisible = !this.modalIsVisible;
        },
      },
    });

    $('.owl-carousel-home-page').owlCarousel({
      loop: false,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
          dots: false,
        },
        600: {
          items: 1,
          nav: false,
          dots: false,
        },
        1000: {
          items: 1,
          nav: true,
          loop: false,
          dots: false,
        },
      },
    });

    $(".reviews-carousel").slick({
      arrows: false,
      dots: true,
      cssEase: "linear",
      autoplay: true,
      autoplaySpeed: 20000,
      slidesToShow: 1,
      slidesToScroll: 1,
    });

    $(".world-carousel").slick({
      arrows: false,
      dots: true,
      cssEase: "linear",
      autoplay: true,
      autoplaySpeed: 20000,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [{
          breakpoint: 1064,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    const maxSlidesCount = 5;
    const subscriptionSlidesCount = $('.subscription-carousel').children().length;
    const vipSlidesCount = $('.vip-carousel').children().length;
    const sharedSliderOptions = {
      infinite: true,
      dots: false,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 835,
          settings: {
            slidesToShow: 2,
          }
        },
      ]
    };

    $('.subscription-carousel').slick({
      ...sharedSliderOptions,
      slidesToShow: Math.min(maxSlidesCount, subscriptionSlidesCount),
      slidesToScroll: subscriptionSlidesCount <= maxSlidesCount ? 1 : 3,
    });

    $('.vip-carousel').slick({
      ...sharedSliderOptions,
      slidesToShow: Math.min(maxSlidesCount, vipSlidesCount),
      slidesToScroll: vipSlidesCount <= maxSlidesCount ? 1 : 3,
    });
  }

  howItWorks() {
    new Vue({
      el: '#how-it-works-banner',
      components: {
        HowItWorksBanner: HowItWorksBanner,
      }
    });

    new Vue({
      el: '#how-it-works-products',
      components: {
        HowItWorksProducts: HowItWorksProducts,
      }
    });
    
    this._testimonialCarousel();
  }

  login() {
    new Vue({
      el: '#login-app',
      components: {
        Login
      },
    });
  }

  becomeAVendor() {
    var helpers = new Helpers();
    // set carousel items same height
    helpers.sameHeight('#carousel-blockquote .carousel-item');
    helpers.sameHeight('#carousel-testimonial .carousel-item');
    $('.owl-carousel-become-a-vendor').owlCarousel({
      loop: false,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
          dots: false,
        },
        600: {
          items: 1,
          nav: false,
          dots: true,
        },
        1000: {
          items: 1,
          nav: true,
          loop: false,
          dots: false,
        },
      },
    });
  }

  testimonials() {
    this._testimonialCarousel();

    $('.trending-carousel').slick({
      arrows: false,
      dots: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [{
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    $('.review-carousel').slick({
      arrows: false,
      dots: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 15000,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  }

  planB() {
    $('.sub-carousel').slick({
      arrows: true,
      dots: false,
      centerMode: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 3,
      infinite: true,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]
    });

    $('.review-carousel').slick({
      arrows: false,
      dots: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 15000,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  }

  vip() {
    $('.vip-page-carousel').slick({
      arrows: true,
      dots: false,
      centerMode: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 3,
      infinite: true,
      responsive: [
        {
          breakpoint: 1050,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]
    });

    $('.review-carousel').slick({
      arrows: false,
      dots: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 15000,
      slidesToShow: 1,
      slidesToScroll: 1,
    });
  }

  couture() {
    this._testimonialCarousel();
  }

  bridal() {
    this._testimonialCarousel();
  }

  _testimonialCarousel() {
    $('.testimonial-carousel').slick({
      arrows: false,
      dots: true,
      cssEase: 'linear',
      autoplay: false,
      autoplaySpeed: 15000,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [{
        breakpoint: 1050,
        settings: {
          dots: true,
          swipe: true,
          arrows: false,
        },
      }, ],
    });
  }
}
