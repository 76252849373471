<template>
  <div class="right-column">
    <div class="toggle-details">
      <p v-if="!isVisible" @click="detailsToggle">View Order</p>
      <p v-else @click="detailsToggle">Hide Order</p>
      <span id="toggle-chevron"></span>
    </div>
    <div class="toggle-div">
      <div class="checkout-details">
        <div v-if="isSubscription" class="membership-details">
          <div class="details-spacer-mobile" />
          <h5 class="details-h5 membership">Membership Summary</h5>
          <p>Subscription plan membership is free for the first month.</p>
          <p>After that, your card will be charged $59 every month.</p>

          <ul>
            <li>Automatic renewal for your convenience.</li>
            <li>Cancel anytime.</li>
            <li>No commitment.</li>
          </ul>
          <div class="details-spacer" />
        </div>

        <h5 class="details-h5">Order Summary</h5>
        <div class="items-container">
          <div class="order-item" v-for="product in products" :key="product.id">
            <a :href="product.links.public">
              <img
                v-if="product.image.data"
                :src="product.image.data.variants.square_thumb"
                alt="image"
              />
            </a>
            <div class="order-item-details">
              <p>{{ product.title }}</p>
              <ul></ul>
            </div>
            <div class="price">
              <p>{{ product.rental_format }}/day</p>
            </div>
          </div>
        </div>
        <hr />
        <div v-if="isSubscription" class="prices prices-checkout">
          <div class="prices-line">
            <p>Subtotal</p>
            <p>{{ subscriptionPrice }}</p>
          </div>
          <div v-if="this.couponCode" class="prices-line coupon-line">
            <p class="coupon-wrapper">Code Applied "{{ this.couponCode }}"</p>
            <p>-{{ this.couponDiscount }}</p>
          </div>
          <div v-if="this.couponCode" class="remove-code">
            <a href="/cart/remove_coupon">remove</a>
          </div>
          <div class="prices-line">
            <p>Shipping Fee</p>
            <p>FREE</p>
          </div>
          <div v-if="isFirstMonth" class="prices-line">
            <p>One Month Free Promotion</p>
            <p>-{{ subscriptionPrice }}</p>
          </div>
          <div class="prices-line">
            <p>TOTAL</p>
            <p v-if="isFirstMonth">$0.00</p>
            <p v-else>{{ subscriptionPrice }}</p>
          </div>
        </div>
        <div v-else class="prices prices-checkout">
          <div class="prices-line">
            <p>Subtotal</p>
            <p>{{ this.order.rental_price_formatted }}</p>
          </div>
          <div class="prices-line">
            <p>Store credit</p>
            <p>{{ this.order.store_credit_formatted }}</p>
          </div>
          <div v-if="this.couponCode" class="prices-line coupon-line">
            <p>Code Applied "{{ this.couponCode }}"</p>
            <p>-{{ this.couponDiscount }}</p>
          </div>
          <div v-if="this.couponCode" class="remove-code">
            <a href="/cart/remove_coupon">remove</a>
          </div>
          <div class="prices-line">
            <p>Shipping Fee</p>
            <p>FREE</p>
          </div>
          <div class="prices-line">
            <p>TOTAL</p>
            <p>{{ this.finalPrice }}</p>
          </div>
        </div>

        <hr class="after-prices" />
        <div class="dates">
          <p>Estimated Delivery {{ this.rent_at }}</p>
          <p>Return Date {{ this.return_at }}</p>
        </div>
        <hr />
        <p v-if="!this.couponCode & !this.isSubscription" class="promo">
          Gift/Promo Code
        </p>
        <coupon
          v-if="!this.couponCode & !this.isSubscription"
          path="/cart/coupon"
          @updateCoupon="updateCoupon"
        />
      </div>
      <p class="need-help">
        Need help or have questions? Read our <a href="/faq">FAQ.</a>
      </p>
    </div>
    <hr class="hr-mobile" />
    <div class="nav" id="nav-mobile">
      <div class="before-chevron" />
      <a href="/" class="back-to-cart">Back to Shopping Cart</a>
    </div>
  </div>
</template>

<script>
import Coupon from "./coupon_cart";
export default {
  props: {
    products: { type: Array },
    order: { type: Object },
    rent_at: { type: String },
    return_at: { type: String },
    isSubscription: { type: Boolean },
  },
  components: {
    coupon: Coupon,
  },
  data() {
    return {
      isVisible: true,
      couponCode: "",
      couponDiscount: "",
      finalPrice: this.order.rental_end_price_formatted,
      isFirstMonth: this.order.is_first_subscription_order,
      subscriptionPrice: "$59.00",
    };
  },
  mounted() {
    if (this.order.coupon.data) {
      this.couponCode = this.order.coupon.data.code;
      this.couponDiscount = this.order.rental_discount_formatted;
    }
  },
  computed: {
    planName() {
      return this.isSubscription ? "SUBSCRIPTION BOX" : "RENT BY THE DAY";
    },
  },
  methods: {
    detailsToggle: function () {
      const toggler = $(".toggle-div");
      const chevron = $("#toggle-chevron");

      if (this.isVisible) {
        toggler.css("max-height", toggler[0].scrollHeight);
        setTimeout(() => {
          toggler.css("max-height", "0");
          chevron.css("transform", "rotate(270deg)");
          this.isVisible = false;
        }, 300);
      } else {
        toggler.css("max-height", toggler[0].scrollHeight);
        chevron.css("transform", "none");
        this.isVisible = true;
      }
    },
    updateCoupon: function (code, discount, finalPrice) {
      this.couponCode = code;
      this.couponDiscount = discount;
      this.finalPrice = finalPrice;
    },
  },
};
</script>

<style lang="scss">
.remove-code {
  margin-top: -1.5em;
  margin-bottom: 21px;
  a {
    color: grey;
  }
}
@media (max-width: 900px) {
  .remove-code {
    margin-top: -1em;
  }
}
</style>
