<template>
  <div class="calendar">
    <div class="calendar-body">

      <h4 class="component-heading">Minumum rental time one week</h4>
      <img src="~images/app/products/calendar-icon.png" class="cta-calendar" @click="ctaCalendar" />
      
      <flat-pickr
        v-model="date"
        :config="config"
        placeholder="Select a date"
        @on-change="setRange"
      >
      </flat-pickr>
      <div class="calendar-popup">
        <p id="calendar-close">X</p>
        <div class="calendar-legend-weekdays">
          <!-- Delivery is NOT available on weekends -->

          <div class="mt-20">
            <button
              v-if="isVariantInOrder"
              class="btn btn-primary change-date"
              @click="updateDatesForOrderAndClose"
              :id="'change-date-' + productId"
            >
              Change Date
            </button>
            <button
              v-else-if="order.limit_reached"
              class="btn btn-primary btn-limit-reached"
              data-toggle="tooltip"
              data-placement="top"
              title="Remove items from your cart to add new"
            >
              Limit reached
            </button>
            <button
              v-else-if="canAddToCart"
              class="btn btn-primary rbtd-added-button px-45 pt-10 pb-15"
              @click="addToCartAndClose"
            >
              RESERVE
            </button>
            
          </div>
        </div>
        <div class="calendar-texts">
          <p class="rbtd-text">Select Your Rental Dates</p>
        </div>

        <div class="calendar-legend mt-15">
            <span class="receive"> Receive </span>
            <span class="return"> Return </span>
            <span class="unavailable"> Unavailable </span>
          </div>
      </div>
    </div>

    <div class="calendar-footer">
      <div class="calendar-buttons" v-if="isUserAccount">
        <button
          v-if="isVariantInOrder"
          class="btn btn-primary change-date"
          @click="updateDatesForOrder"
          :id="'change-date-' + productId"
        >
          Change Date
        </button>
        <button
          v-else-if="order.limit_reached"
          class="btn btn-primary btn-limit-reached"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove items from your cart to add new"
        >
          Limit reached
        </button>
        <button
          v-else-if="!canAddToCart"
          class="btn btn-primary"
          data-toggle="tooltip"
          data-placement="top"
          :title="'Product not allowed for ' + shopping_mode + ' shopping mode'"
        >
          RESERVE THIS PIECE
        </button>
        <button
          v-else
          class="btn btn-primary rbtd-added-button"
          @click="addToCart"
        >
          RESERVE THIS PIECE
        </button>

        <button
          v-if="isVariantInOrder"
          class="btn btn-danger px-3"
          @click="removeFromCart"
          :id="'remove-from-cart-' + productId"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove from cart"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div v-else class="calendar-buttons">
        <button class="btn btn-primary" @click="requireLogin">
          RESERVE THIS PIECE
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/airbnb.css";
import * as Toastr from "toastr";
import EventBus from '../../event_bus';
import GtmEvents from '../../gtm_events';

export default {
  props: {
    path: { type: String, required: true },
    change_date_for_order_path: { type: String, required: true },
    calendar: { type: Object, required: true },
    order: { type: Object, required: true },
    product: { type: Object, required: true },
    shopping_mode: { type: String, required: true },
    account_type: { type: String, required: true },
  },
  components: {
    flatPickr,
  },
  data() {
    return {
      date: null,
      startDate: null,
      endDate: null,
      productId: this.product.id,
      // https://chmln.github.io/flatpickr/options/
      config: {
        inline: true,
        altFormat: "D m/d",
        altInput: true,
        altInputClass: "calendar-info",
        mode: "range",
        minDate: null,
        disable: [
          function(date) {
            return (date.getDay() === 0 || date.getDay() === 6);
          },
        ],
        defaultDate: null,
        appendTo: "${.calendar-popup}",
        monthSelectorType: "static",
        maxDate: this.dateSixMonthsLaterFromNow(),
        onReady: (selectedDates, dateStr, instance) => {
          this.flatpickrInstance = instance;
        }
      },
      dateFormatOptions: { year: "numeric", month: "long", day: "numeric" },
      flatpickrOptions: {
        onReady: (selectedDates, dateStr, instance) => {
          this.flatpickrInstance = instance;
        }
      },
      flatpickrInstance: null
    };
  },
  mounted() {
    EventBus.$on('buttonReload', (order) => {
      this.buttonReload(order);
    });
  },
  created: function () {
    for (let i = 0; i < this.calendar.reservations.length; i++) {
      let reservationDate = {
        from: this.calendar.reservations[i][0],
        to: this.calendar.reservations[i][1],
      };
      this.config.disable.push(reservationDate);
    }
    this.config.defaultDate = this.calendar.period;
    this.config.minDate = this.minDate;
    if (this.calendar.period) {
      this.startDate = new Date(this.calendar.period[0]);
      this.endDate = new Date(this.calendar.period[1]);
    }
  },
  methods: {
    buttonReload(order) {
      this.order = order;
    },
    requireLogin() {
      $("#modal-login").modal("show");
      Toastr.info(
        "You should log in/create your account to reserve this item",
        "Authentication required"
      );
    },
    cartReload(data) {
      EventBus.$emit('cartReload', data);
    },
    updateDatesForOrderAndClose() {
      this.updateDatesForOrder();
      this.$emit('close')
    },
    ctaCalendar() {
      if (this.isUserAccount) {
        $($('.calendar-toggle')[0]).click()
      } else {
        this.requireLogin();
      }
    },
    updateDatesForOrder() {
      var that = this;
      $.ajax({
        method: "PUT",
        data: {
          cart: {
            rent_at: that.startDate,
            variant_id: that.variantId,
          },
        },
        url: that.change_date_for_order_path,
        success: function (data) {
          var msg = that.isVariantInOrder
            ? "Reservation date changed"
            : "Product added to cart";
          that.$parent.order = data.order;
          that.$parent.calendar = data.calendar;
          $(".nav-link-cart span").text(data.order.items_count);
          Toastr.success(msg, "Success");
          data.startDateFormatted = moment(new Date(that.startDate)).format("DD MMM, YYYY");
          data.endDateFormatted = moment(new Date(that.endDate)).format("DD MMM, YYYY");
          data.shopping_mode = "rbtd";
          that.cartReload(data);
          const cart = $("#cart-container");
          const overlay = $(".overlay-close");
          setTimeout(function () {
            cart.css("right", "0");
            overlay.css("opacity", "1");
            overlay.css("visibility", "visible");
          }, 1000);
          setTimeout(function () {
            $("#change-date-" + that.productId).html("CHANGE DATE");
            $("#change-date-" + that.productId).addClass("change-date");
            $("#remove-from-cart-" + that.productId).show();
          }, 2000);
        },
        error: function (data) {
          Toastr.error(data.responseJSON.errors_full.join(), "Reserve error");
        },
      });
    },
    addToCartAndClose() {
      this.addToCart()
      this.$emit('close')
    },
    addToCart() {
      if (this.startDate == null) {
        $($('.calendar-toggle')[0]).click()
        return;
      }

      var that = this;
      $.ajax({
        method: "PUT",
        data: {
          cart: {
            rent_at: that.startDate,
            variant_id: that.variantId,
          },
        },
        url: that.path,
        success: function (data) {
          var msg = that.isVariantInOrder
            ? "Reservation date changed"
            : "Product added to cart";
          that.$parent.order = data.order;
          that.$parent.calendar = data.calendar;
          $(".nav-link-cart span").text(data.order.items_count);
          // Toastr.success(msg, "Success");

          data.addedToCartId = +that.variantId;
          data.startDateFormatted = moment(new Date(data.calendar.period[0])).format("DD MMM, YYYY");
          data.endDateFormatted = moment(new Date(data.calendar.period[1])).format("DD MMM, YYYY");
          that.cartReload(data);
          GtmEvents.trackItemEvent('add_to_cart', { order_data: data })

          setTimeout(function() {
            $('#shop').click();
          }, 300);
          
        },
        error: function (data) {
          Toastr.error(data.responseJSON.errors_full.join(), "Reserve error");
        },
      });
    },
    removeFromCart() {
      var that = this;
      $.ajax({
        method: "DELETE",
        data: {
          cart: {
            variant_id: that.variantId,
          },
        },
        url: that.path,
        success: function (data) {
          that.$parent.order = data.order;
          that.$parent.calendar = data.calendar;
          $(".nav-link-cart span").text(data.order.items_count);
          Toastr.success("Product removed from cart!", "Success");
          data.removedFromCartId = +that.variantId;
          that.cartReload(data);
          GtmEvents.trackItemEvent('remove_from_cart', { order_data: data })
          $("#change-date-" + that.productId).html("RESERVE THIS PIECE");
        },
        error: function (data) {
          Toastr.error(data.responseJSON.errors.join(), "Reserve error");
        },
      });
    },
    setRange(selectedDates, dateStr, instance) {
      this.startDate = moment(dateStr)._d;
      this.endDate = moment(dateStr).add(7, "days")._d;
      if(this.isPeriodAboveSixMonthsFromNow(this.startDate, this.endDate)){
        Toastr.error("You can reserve up to 6 months from today", "Reserve error");
        instance.setDate([]);
      }
      else if (this.checkAvailability(this.startDate, this.endDate)) {
        selectedDates.push(this.endDate);
        instance.setDate(selectedDates);
      } else {
        Toastr.error(
          "The selected period is not available.",
          "Reservation error"
        );
        instance.setDate([]);
      }
    },
    getFormattedDate(date) {
      if (date == null) {
        return "Select reserve date";
      } else {
        return date.toLocaleDateString("en-US", this.dateFormatOptions);
      }
    },
    // Check if requested period is available. Returns true if
    // requested period is not in another reservation.
    //
    // reservations - array of arrays with start and end date of each reservation
    // [
    //    ["2018-07-30", "2018-08-01"],
    //    ["2018-08-07", "2018-08-14"]
    // ]
    // start - Date object requested start date
    // end - Date object requested end date
    checkAvailability(start, end) {
      if (!(start instanceof Date) || !(end instanceof Date)) {
        throw "Invalid date formats. Please provide Date objects.";
      }
      if (start > end) {
        throw "Start date is bigger than end date. Please set correct values";
      }
      var available = true;
      // check each reservation for the selected period
      $.each(this.calendar.reservations, function (index, value) {
        var reservedStart = new Date(value[0].replace("-", "/"));
        var reservedEnd = new Date(value[1].replace("-", "/"));
        if (reservedStart >= start && reservedStart <= end) {
          available = false;
        }
        // reservedEnd not needed as the dates are disabled
        // if (reservedEnd >= start && reservedEnd <= end)  { available = false };
      });
      return available;
    },
    // Check if requested period is above 6 months from now. Returns true if
    // requested period is above 6 months from now.
    // start - Date object requested start date
    // end - Date object requested end date
    isPeriodAboveSixMonthsFromNow(start, end) {
      if (!(start instanceof Date) || !(end instanceof Date)) {
        throw "Invalid date formats. Please provide Date objects.";
      }
      if (start > end) {
        throw "Start date is bigger than end date. Please set correct values";
      }
      if (start >= this.dateSixMonthsLaterFromNow()) {
        return true;
      } else {
        return false;
      }
    },
    // Returns array of date range with all days between given 2 dates
    //
    // start - Date object start date
    // end - Date object end date
    getAllDays(start, end) {
      if (!(start instanceof Date) || !(end instanceof Date)) {
        throw "Invalid date formats. Please provide Date objects.";
      }
      if (start > end) {
        throw "Start date is bigger than end date. Please set correct values";
      }
      var array = [];
      var tmpStart = new Date(start);
      array.push(start);
      while (tmpStart < end) {
        array.push(tmpStart);
        // tmpStart = new Date(tmpStart.setDate(tmpStart.getDate() + 1));
        tmpStart = moment(tmpStart).add(1, "day")._d;
      }
      return array;
    },
    dateSixMonthsLaterFromNow: function () {
      return moment().add(6, 'months').toDate();
    }
  },
  computed: {
    minDate() {
      let workdaysToAdd = gon.first_time_customer ? 5 : 3;

      for(let i = 0; i <= workdaysToAdd; i++) {
        let day = moment(new Date()).add(i, "days").day()
        if(day == 0 || day == 6) workdaysToAdd++;
      }

      return moment(new Date()).add(workdaysToAdd, "days").format("YYYY-MM-DD");
    },
    startDateFormatted: function () {
      if (this.startDate == null) {
        // returns default string
        return this.getFormattedDate(this.startDate);
      }
      var date = new Date(this.startDate);
      date.setDate(this.startDate.getDate() - 2);
      return this.getFormattedDate(date);
    },
    arriveDateFormatted: function () {
      if (this.startDate == null) {
        // returns default string
        return this.getFormattedDate(this.startDate);
      }
      var date = new Date(this.startDate);
      date.setDate(this.startDate.getDate() - 1);
      return this.getFormattedDate(date);
    },
    endDateFormatted: function () {
      if (this.endDate == null) {
        // returns default string
        return this.getFormattedDate(this.endDate);
      }
      var date = new Date(this.endDate);
      date.setDate(this.endDate.getDate() + 1);
      return this.getFormattedDate(date);
    },
    variantId: function () {
      return this.product.master.data.id;
    },
    isVariantInOrder: function () {
      return this.order.variant_ids.includes(parseInt(this.variantId));
    },
    isUserAccount: function () {
      return this.account_type == "User";
    },
    canAddToCart: function () {
      return (
        this.isUserAccount &&
        this.shopping_mode.toLowerCase() ==
          this.product.purchase_option.toLowerCase()
      );
    },
  },
};
</script>

<style media="screen">
.flatpickr-day.selected,
.flatpickr-day.selected.startRange,
.flatpickr-day.selected.endRange {
  background: rgba(159, 19, 82, 0.27);
  border-color: rgba(159, 19, 82, 0.27);
}
.calendar .flatpickr-input {
  display: none;
}
.flatpickr-calendar.inline {
  box-shadow: none;
  margin: 0 auto;
  margin-bottom: 15px;
}
.flatpickr-day.prevMonthDay:not(.disabled),
.flatpickr-day.nextMonthDay:not(.disabled) {
  color: rgba(57, 57, 57, 0.6);
}
.btn.btn-limit-reached {
  cursor: not-allowed !important;
}
.flatpickr-calendar {
  max-width: 100%;
}

.change-date {
  background: #9f1353;
  border: none;
}

.rbtd-added-button {
  font-size: 18px;
}

.cta-calendar {
  width: 150px;
  height: auto;
  cursor: pointer;
}
</style>
